// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
export const queryKeys = {
	all: () => ['algolia'] as const,

	marketingContent: {
		all: () => [...queryKeys.all(), 'marketingContent'] as const,
		byId: (benefitPlanMarketingContentId) =>
			[...queryKeys.marketingContent.all(), benefitPlanMarketingContentId] as const,
	},
}
