import { Component, useEffect, useState } from 'react'
import { useLocation } from 'react-router'

export class ErrorBoundaryInner extends Component<
	{ children: any; hasError: boolean; setHasError: (v: boolean) => void },
	{ hasError: boolean; setHasError: (v: boolean) => void }
> {
	constructor(props) {
		super(props)
		this.state = { hasError: false, setHasError: props.setHasError }
	}

	static getDerivedStateFromError() {
		return { hasError: true }
	}

	componentDidUpdate(prevProps) {
		if (!this.props.hasError && prevProps.hasError) {
			this.setState({ hasError: false })
		}
	}

	componentDidCatch() {
		this.props.setHasError(true)
	}

	render(): JSX.Element {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div
					style={{
						alignItems: 'center',
						display: 'flex',
						flexDirection: 'column',
						gap: '1rem',
						height: '100vh',
						justifyContent: 'center',
					}}
				>
					<h2>Something went wrong.</h2>
					<h3>Please refresh your page or try again later.</h3>
				</div>
			)
		}

		return this.props.children
	}
}

/**
 * NEW: The error boundary has a function component wrapper.
 */
export default function ErrorBoundary({ children }) {
	const [hasError, setHasError] = useState(false)
	const location = useLocation()
	useEffect(() => {
		if (hasError) {
			setHasError(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.key])

	return (
		/**
		 * NEW: The class component error boundary is now
		 *      a child of the functional component.
		 */
		<ErrorBoundaryInner hasError={hasError} setHasError={setHasError}>
			{children}
		</ErrorBoundaryInner>
	)
}
