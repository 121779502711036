import { algoliaClient, faqsIndex } from 'config/search-config'
import { StateMachineStatus } from 'hooks/use-async-fetch'
import { ReactNode, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { siteInfoSelector } from 'store/site/selectors'
import { ERROR, IDLE, LOADING, SUCCESS } from 'types/status-types'
import FAQAccordion from '../../components/faq-accordion'
import { FAQ, FaqData } from './data'
import styles from './faq.module.css'
import { FaqContentContainer } from './styles'

type FAQWrapperProps = {
	children: ReactNode
	headingTitle?: ReactNode
}
function FAQWrapper(props: FAQWrapperProps): JSX.Element {
	return (
		<div className={styles['faq-container']}>
			<FaqContentContainer className={styles['faq-content-container']}>
				<div className={styles['faq-hero-container']}>
					<h1 className={styles['faq-hero-text']}>{props.headingTitle || 'Frequently Asked Questions'}</h1>
				</div>
			</FaqContentContainer>
			<div className={styles['faq-details-container']}>{props.children}</div>
		</div>
	)
}

type FAQBlockProps = {
	faq: FAQ
	headingTitle?: ReactNode
}
function FAQBlock(props: FAQBlockProps): JSX.Element {
	return (
		<FAQWrapper headingTitle={props.headingTitle}>
			{props.faq.questionsAnswers.map((qa, index) => (
				<FAQAccordion key={index} question={`${qa.order}. ${qa.question}`} answer={qa.answer} />
			))}
		</FAQWrapper>
	)
}

const FAQPage = () => {
	const [faqFetchStatus, setFaqFetchStatus] = useState<StateMachineStatus>(IDLE)
	const [faqs, setFaqs] = useState<FAQ | undefined>(undefined)
	const siteInfo = useSelector(siteInfoSelector)

	useEffect(() => {
		const fetchFaqs = async (): Promise<void> => {
			setFaqFetchStatus(LOADING)

			try {
				const searchResponse = await algoliaClient.searchForHits<FaqData>({
					requests: [
						{
							filters: `tenantID: ${siteInfo.organizationId}`,
							hitsPerPage: 1000,
							indexName: faqsIndex,
						},
					],
				})

				const faqs = searchResponse.results[0]?.hits ?? []

				setFaqs({ questionsAnswers: faqs.sort((a, b) => +(a?.order ?? 0) - +(b?.order ?? 0)) })
				setFaqFetchStatus(SUCCESS)
			} catch (e) {
				setFaqFetchStatus(ERROR)
			}
		}

		if (siteInfo.organizationId && faqFetchStatus === IDLE) {
			fetchFaqs()
		}
	}, [faqFetchStatus, siteInfo.organizationId])

	return faqs ? <FAQBlock faq={faqs as FAQ} /> : null
}

export default FAQPage

export const Component = FAQPage
