import { Close, Search } from '@mui/icons-material'
import { Button, IconButton, Input, InputAdornment } from '@mui/material'
import { Transition } from 'hooks/use-navigation-popup'
import { FC, memo, useCallback, useMemo, useState } from 'react'
import { useInstantSearch, useSearchBox } from 'react-instantsearch'
import { BlockerFunction, useBlocker, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { buildUrl } from 'utils/url'
import styles from './search-box.module.css'

export type SearchBoxProps = {
	closeMenu?: () => void
	id?: string
	onCancelClick: () => void
	track: (query: any) => unknown
}

let timerId
const delay = 800

const SearchBox: FC<SearchBoxProps> = memo(({ closeMenu, id, onCancelClick, track }: SearchBoxProps) => {
	const location = useLocation()

	const isLoading = useInstantSearch().status === 'loading'
	const { clear, refine } = useSearchBox({
		queryHook: useCallback(
			(query, hook) => {
				clearTimeout(timerId)
				timerId = setTimeout(() => {
					track(query)
					hook(query)
				}, delay)
			},
			[track],
		),
	})

	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const [query, setQuery] = useState(searchParams.get('query') ?? '')

	const updateUrl = useCallback(
		(query: string): void => {
			const searchRoute: { pathname: string; search?: string } = { pathname: location.pathname }
			const category = searchParams.get('category')
			const brand = searchParams.get('brand')
			if (query.length > 0 || brand || category) {
				searchRoute.pathname = '/search'
				let search = ''
				if (query.length > 0) {
					search = `query=${encodeURIComponent(query)}`
				}
				if (brand) {
					search = search ? `${search}&${buildUrl(brand, 'brand')}` : `${buildUrl(brand, 'brand')}`
				}
				searchRoute.search = search
			}

			navigate(`${searchRoute.pathname}${searchRoute.search ? '?' + searchRoute.search : ''}`)
		},
		[location.pathname, navigate, searchParams],
	)

	const clearSearchValue = useCallback(
		(shouldRedirect: boolean = false): void => {
			clear()
			setQuery('')

			if (shouldRedirect) {
				updateUrl('')
			}
		},
		[clear, updateUrl],
	)

	const handleNavigateAwayFromSearch = useCallback<BlockerFunction>(
		(history: Transition) => {
			if (
				!history.nextLocation.pathname.includes('search') &&
				location.pathname.includes('search') &&
				query.length > 0
			) {
				clearSearchValue()
			}

			return false
		},
		[location.pathname, query.length, clearSearchValue],
	)

	useBlocker(handleNavigateAwayFromSearch)

	/**
	 * kicks off the search
	 * @param searchVal value to search on
	 * @returns nothing
	 */
	const search = useCallback(
		(searchVal: string): void => {
			refine(searchVal)
			document.getElementById('searchInput')?.blur()
			updateUrl(searchVal)
		},
		[refine, updateUrl],
	)

	// /**
	//  * Trigger focus on page render
	//  */
	const onKeyPressed = (e): void => {
		if (e.key === 'Enter') {
			search(e.target.value)
		}
	}

	const onSearchIconClick = useCallback(
		(e): void => {
			e.preventDefault()
			search(query)
		},
		[query, search],
	)

	const onChange = (e): void => {
		e.preventDefault()

		const v = e.currentTarget.value
		setQuery(v)
		if (closeMenu) closeMenu()
	}

	const isClearIconVisible = query.length > 0

	const endAdornment: JSX.Element = useMemo(
		() => (
			<InputAdornment position='end'>
				<div className={styles['icons']}>
					{isClearIconVisible && (
						<IconButton
							aria-label='Clear Search'
							id='button-clear-search'
							onClick={() => clearSearchValue(true)}
							size='large'
						>
							{<Close />}
						</IconButton>
					)}
					<IconButton aria-label='Search' id='button-search' onClick={onSearchIconClick} size='large' edge='end'>
						<Search />
					</IconButton>
				</div>
			</InputAdornment>
		),
		[clearSearchValue, isClearIconVisible, onSearchIconClick],
	)

	return (
		<div className={styles['container']}>
			<div className={styles['input-container']}>
				<Input
					id={id ?? 'searchInput'}
					className={styles['search-bar']}
					onChange={onChange}
					onKeyDown={onKeyPressed}
					placeholder='What can we help you find?'
					value={query}
					disabled={isLoading}
					endAdornment={endAdornment}
					disableUnderline
					inputProps={{
						'aria-label': 'What can we help you find?',
					}}
					autoComplete='cs-search'
					name='Benefits and Offers Search'
				/>
			</div>
			<Button
				variant='text'
				color='error'
				aria-label={'Cancel Search'}
				className={styles['cancel']}
				onClick={onCancelClick}
			>
				CANCEL
			</Button>
		</div>
	)
})

SearchBox.displayName = 'SearchBox'

export default SearchBox
