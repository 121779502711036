import { CloseOutlined, MenuOutlined, Search } from '@mui/icons-material'
import { AppBar, IconButton, useMediaQuery, useTheme } from '@mui/material'
import { cx } from 'class-variance-authority'
import { categories, events } from 'config/analytics'
import { useTrackDetailedPageEvent } from 'hooks/analytics'
import { useTrackEvent } from 'hooks/use-track-event'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ReactNode, createContext, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { routesMap } from 'routes/routes-map'
import { isLoggedInSelector, userSelector } from 'store/user/selectors'
import { match } from 'ts-pattern'
import { MQ_2LG } from 'types/breakpoints'
import { isPathWithinGroup } from 'utils/url'
import { isMobileSafari } from 'utils/utils'
import { siteInfoSelector } from '../../store/site/selectors'
import { SiteInfoResponse } from '../../types/tenant-site-types'
import styles from './app-header.module.css'
import { MegaMenuItem } from './components/mega-menu/types'
import MobileMenu from './components/mobile-menu'
import MyProfileTab from './components/my-profile-tab/my-profile-tab'
import NavigationTabs from './components/navigation-tabs'
import { DefaultTabs } from './components/navigation-tabs/default-tabs'
import SearchBox from './components/search-box'
import UserMenu from './components/user-menu'
import { Tabs } from './types'

type MegaMenuContextType = {
	benefitMenuItems?: MegaMenuItem[]
	dealsMenuItems?: MegaMenuItem[]
	supplementalBenefitItems?: MegaMenuItem[]
	supplementalDealsItems?: MegaMenuItem[]
}

export const MegaMenuContext = createContext<MegaMenuContextType>({
	benefitMenuItems: [],
	dealsMenuItems: [],
	supplementalBenefitItems: [],
	supplementalDealsItems: [],
})

const routeGroups = {
	benefits: [
		routesMap.autoQuote,
		routesMap.benefitCategory,
		routesMap.benefitsEnroll,
		routesMap.benefitsEnrollConfirm,
		routesMap.benefitsEnrollInfo,
		routesMap.benefitsEnrollPlan,
		routesMap.benefitPlan,
		routesMap.ltc,
	],
	deals: [routesMap.deals.base, routesMap.deals.travel.path],
	help: [routesMap.faq],
	home: [routesMap.home, routesMap.adminLogin, routesMap.login],
	profile: [routesMap.myProfile],
}

export const AppHeader = memo(() => {
	const { wrp1696, und8908_turn_off_powered_by_corestream, b4b_10023_custom_event_migration } = useFlags()
	const [showMenu, setShowMenu] = useState<boolean>(false)
	const [showMobileSearchContainer, setShowMobileSearchContainer] = useState<boolean>(false)
	const [userMenuAnchor, setUserMenuAnchor] = useState<HTMLElement | null>(null)
	const appHeaderContainerRef = useRef<HTMLElement | null>(null)
	const searchFocusRef = useRef<boolean>(true)
	const trackEvent = useTrackEvent()
	const user = useSelector(userSelector, shallowEqual)
	const location = useLocation()
	const theme = useTheme()
	const mobileView = useMediaQuery(theme.breakpoints.down(MQ_2LG + 1))
	const isLoggedIn: boolean = useSelector(isLoggedInSelector)
	const track = useTrackDetailedPageEvent()

	const { properties }: SiteInfoResponse = useSelector(siteInfoSelector)
	const logoUrl = properties.logoImage

	const closeMenu = (): void => {
		if (!showMenu) return

		setShowMenu(false)
	}

	/* Tracks abondment from the login, register, and forgot password pages when the
	 * user clicks the 'go to home page' logo link.
	 */
	const handleAbondonment = (): void => {
		const isOnLoginPage = location.pathname === '/login'
		const isOnRegisterPage = location.pathname === '/register'
		const isOnForgotPasswordPage = location.pathname === '/forgot-password'

		if (isOnLoginPage) {
			if (b4b_10023_custom_event_migration) {
				track({
					actionType: 'on leave',
					elementName: 'user abandoned',
					elementType: 'page',
					pageName: 'login',
				})
			} else {
				trackEvent(events.user.abandoned.login)
			}
		} else if (isOnRegisterPage) {
			if (b4b_10023_custom_event_migration) {
				track({
					actionType: 'on leave',
					elementName: 'user abandoned',
					elementType: 'page',
					pageName: 'register',
				})
			} else {
				trackEvent(events.user.abandoned.registration)
			}
		} else if (isOnForgotPasswordPage) {
			if (b4b_10023_custom_event_migration) {
				track({
					actionType: 'on leave',
					elementName: 'user abandoned',
					elementType: 'page',
					pageName: 'forgot password',
				})
			} else {
				trackEvent(events.user.abandoned.passwordReset)
			}
		}
	}

	const sendQueryAnalytics = useCallback(
		(query): void => {
			if (b4b_10023_custom_event_migration) {
				track({
					actionType: 'click',
					elementDetails: query,
					elementName: 'search query',
					elementType: 'textbox',
				})
			} else {
				trackEvent(events.product.searched, { category: categories.discounts, label: query, query })
			}
		},
		[b4b_10023_custom_event_migration, track, trackEvent],
	)

	const handleCloseUserMenu = (): void => setUserMenuAnchor(null)
	const toggleMenuState = (): void => setShowMenu((prevMenuState: boolean): boolean => !prevMenuState)

	/**
	 * Toggle showing of search box for mobile.
	 * Hide menu when search icon clicked.
	 * @returns void
	 */
	const handleSearchClick = (): void => {
		if (showMenu) {
			setShowMenu(false)
			setShowMobileSearchContainer(true)

			return
		}

		setShowMobileSearchContainer(!showMobileSearchContainer)
	}

	const handleUserMenuClick = (event): void => {
		closeMenu()

		setUserMenuAnchor(event.currentTarget)
	}

	useEffect(() => {
		// remove mobile container when viewport resizes beyond mobile threshold
		if (!mobileView && showMobileSearchContainer) setShowMobileSearchContainer(false)
	}, [mobileView, showMobileSearchContainer])

	useEffect(() => {
		let focusTimeout
		// give search box focus
		if (showMobileSearchContainer) {
			// allow auto-focus when search box is toggled manually on search page
			if (!searchFocusRef.current) {
				searchFocusRef.current = true

				return
			}
			if (isMobileSafari()) {
				// iOS Safari will let you call focus, but if you do it async, it won't pull up the keyboard
				// This lets you pull up the keyboard using a fake element
				// Then deletes the fake element and focuses the real element
				const fakeInput = document.createElement('input')
				fakeInput.setAttribute('type', 'text')
				fakeInput.style.position = 'absolute'
				fakeInput.style.opacity = '0'
				fakeInput.style.height = '0'
				fakeInput.style.fontSize = '16px' // disable auto zoom
				document.body.prepend(fakeInput)
				// focus so that subsequent async focus will work
				fakeInput.focus({ preventScroll: true })
				focusTimeout = setTimeout(() => {
					document.getElementById('search-page-input')?.focus({ preventScroll: true })
					fakeInput.remove()
				}, 500)
			} else {
				focusTimeout = setTimeout(() => {
					document.getElementById('search-page-input')?.focus({ preventScroll: true })
				}, 500)
			}
		}

		return (): void => clearTimeout(focusTimeout)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showMobileSearchContainer])

	const activeTab = useMemo(
		(): Tabs =>
			match(true)
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				//@ts-ignore
				.with(isPathWithinGroup(location.pathname, routeGroups.home), () => Tabs.Home)
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				//@ts-ignore
				.with(isPathWithinGroup(location.pathname, routeGroups.benefits), () => Tabs.Benefits)
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				//@ts-ignore
				.with(isPathWithinGroup(location.pathname, routeGroups.deals), () => Tabs.Deals)
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				//@ts-ignore
				.with(isPathWithinGroup(location.pathname, routeGroups.help), () => Tabs.Help)
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				//@ts-ignore
				.with(isPathWithinGroup(location.pathname, routeGroups.profile), () => Tabs.Profile)
				.otherwise(() => Tabs.NA),
		[location],
	)

	const renderMenu = (): ReactNode => {
		return (
			<IconButton className={styles['icon-button']} onClick={toggleMenuState} aria-label='Open menu' size='large'>
				{showMenu ? <CloseOutlined /> : <MenuOutlined />}
			</IconButton>
		)
	}

	const callbackHandler = () => {
		handleAbondonment()
		setShowMenu(false)
	}

	const renderNavigationTabs = (): ReactNode => {
		if (mobileView) {
			return null
		}
		if (!isLoggedIn) {
			return <DefaultTabs activeTab={activeTab} user={user} callback={callbackHandler} />
		}

		return <NavigationTabs user={user} appHeaderContainerRef={appHeaderContainerRef} activeTab={activeTab} />
	}

	const handleOrgLogoClick = () => {
		if (b4b_10023_custom_event_migration) {
			track({
				actionType: 'click',
				elementName: 'org logo',
				elementType: 'button',
			})
		} else {
			trackEvent(events.header.orgLogoClicked)
		}
		handleAbondonment()
		closeMenu()
	}

	const myProfileText = useMemo(() => {
		if (mobileView) return ''

		return 'Your Account'
	}, [mobileView])

	return (
		<>
			<AppBar
				className={styles['container']}
				id='app-header'
				// anchor for mega-menu
				ref={appHeaderContainerRef}
				role='banner'
			>
				<div className={styles['container-content']}>
					<div className={styles['brand']}>
						<Link to={user ? '/home' : '/'} onClick={handleOrgLogoClick} title='Go to home page' tabIndex={0}>
							<img
								src={logoUrl}
								className={cx(styles['brand-logo'], {
									[styles['brand-logo-lg']]: wrp1696,
									[styles['brand-logo-sm']]: !wrp1696,
								})}
								alt='Brand logo'
								loading='lazy'
							/>
						</Link>
						{!und8908_turn_off_powered_by_corestream && (
							<caption className={styles['corestream-text']}>Powered by Corestream</caption>
						)}
					</div>
					<div className={styles['content']}>
						{user && (
							<div className={styles['search']}>
								{mobileView ? (
									<IconButton
										className={styles['search-mobile']}
										onClick={() => handleSearchClick()}
										onKeyDown={() => handleSearchClick()}
										role='button'
										tabIndex={0}
										aria-label={showMobileSearchContainer ? 'Close Search' : 'Open Search'}
										aria-expanded={showMenu}
										size='large'
									>
										<Search className={styles['search-icon']} />
									</IconButton>
								) : (
									<SearchBox
										closeMenu={closeMenu}
										track={sendQueryAnalytics}
										onCancelClick={() => setShowMobileSearchContainer(false)}
									/>
								)}
							</div>
						)}

						{renderNavigationTabs()}
						{user && (
							<MyProfileTab
								className={cx(styles['user-container'], {
									[styles['logged-in']]: user,
									[styles['mobile-profile']]: mobileView,
								})}
								text={myProfileText}
								handleOnClick={handleUserMenuClick}
								selected={activeTab === Tabs.Profile}
							/>
						)}
						{mobileView && <div className={styles['mobile-menu']}>{renderMenu()}</div>}
					</div>
				</div>
			</AppBar>
			{showMobileSearchContainer && (
				<div className={styles['mobile-search-input-container']}>
					<SearchBox
						id='search-page-input'
						onCancelClick={() => setShowMobileSearchContainer(false)}
						track={sendQueryAnalytics}
					/>
				</div>
			)}
			{showMenu && isLoggedIn && <MobileMenu closeMenu={(): void => setShowMenu(false)} user={user} />}
			{showMenu && !isLoggedIn && (
				<DefaultTabs activeTab={activeTab} user={user} mobileView={mobileView} callback={callbackHandler} />
			)}
			<UserMenu anchorEl={userMenuAnchor} handleClose={(): void => handleCloseUserMenu()} user={user} />
		</>
	)
})

AppHeader.displayName = 'AppHeaderV2'
