/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { algoliaClient } from 'config/search-config'
import { ReactNode, memo, useMemo } from 'react'
import { InstantSearch } from 'react-instantsearch'

type SearchConfigurationProps = {
	children: ReactNode
}

/**
 * This component is used to configure the search client (InstantSearch)
 * for the offers and benefit plans indices.
 * Children prop allows us to wrap the Search Client around our entire app
 *
 * The index's are configured under the Search component "src\pages\search\search.tsx"
 */
export const AlgoliaSearchConfiguration = memo(({ children }: SearchConfigurationProps): JSX.Element => {
	const searchClient = useMemo(
		() => ({
			...algoliaClient,
		}),
		[],
	)

	return (
		<InstantSearch
			searchClient={searchClient}
			future={{
				persistHierarchicalRootCount: true,
				preserveSharedStateOnUnmount: true,
			}}
			routing={false}
		>
			{children}
		</InstantSearch>
	)
})

AlgoliaSearchConfiguration.displayName = 'SearchConfiguration'

export default AlgoliaSearchConfiguration
