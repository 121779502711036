import { algoliaClient, marketingContentIndex } from 'config/search-config'
import { ModularMarketingContent } from 'store/benefit-plans/types'

export class MarketingContentService {
	static getMarketingContent = (id: string): Promise<ModularMarketingContent> => {
		return algoliaClient.getObject({
			indexName: marketingContentIndex,
			objectID: id,
		}) as unknown as Promise<ModularMarketingContent>
	}
}
