import { capitalize } from '@mui/material'
import { AsyncProviderConfig, LDContext, asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { StrictMode } from 'react'
import { Provider } from 'react-redux'
import UrlBuilder from 'services/util/url-builder'
import { store } from 'store/store'
import { Properties, SiteInfoResponse } from 'types/tenant-site-types'
import { Environments } from 'utils/env'
import { App } from './app'
import { AppContextProvider } from './contexts/app-context'
import { SiteEnvironment } from './types'
import { parseEnvAndDomainFromHost } from './utils/parseEnvAndDomainFromHost'

/**
 * Fetches data related to tenant, sets up LaunchDarkly syncronously, and renders the app afterwards
 */
export async function setupApp(site: SiteEnvironment, root) {
	const siteInfo = await getSiteInfo(site)

	// eslint-disable-next-line no-constant-condition
	if ('error' in siteInfo) {
		// eslint-disable-next-line no-console
		console.trace(siteInfo.error)

		return root.render(
			<div style={{ alignItems: 'center', display: 'flex', gap: '1rem', height: '100vh', justifyContent: 'center' }}>
				<h1>
					An Error Has Occured Retrieving {capitalize(parseEnvAndDomainFromHost(window.location.host).subdomain)}&apos;s
					Tenant Properties:
				</h1>
				<p>{String(siteInfo.error)}</p>
			</div>,
		)
	}

	const LDProvider = await asyncWithLDProvider(await configureLaunchDarkly(site.env as Environments, siteInfo))

	root.render(
		<StrictMode>
			<Provider store={store}>
				<LDProvider>
					<AppContextProvider values={{ site, siteInfo }}>
						<App />
					</AppContextProvider>
				</LDProvider>
			</Provider>
		</StrictMode>,
	)
}

// ********************************************************************************************************************

async function getSiteInfo(site: SiteEnvironment): Promise<SiteInfoResponse | { error: Error | null }> {
	const url = generateTenantUrl({
		env: site.env,
		tenant: site.subdomain,
	})

	// UrlBuilder setHost() sets in sessionStorage
	// when app loads, auth is rebuilt and checks for this host value in sessionStorage
	// yes, this is a hack, but it works

	// eslint-disable-next-line newline-per-chained-call
	const response = await fetch(new UrlBuilder().setHost(site.env).setEndpoint('TenantSites', url).url())
		.then((response) => {
			if (!response.ok) throw new Error(`Network response was not ok: ${response.statusText}`)

			return response.json()
		})
		.then((data) => {
			const result = data as SiteInfoResponse

			result.properties = new Properties(data.properties)

			return result
		})
		.catch((error: Error | null) => ({ error }))

	return response
}

// quick utility function to generate the tenant url
function generateTenantUrl(object: { tenant: string; env: string }): string {
	return `${object.tenant}.${object.env}.corestream.com`
}

/**
 * Setup the LaunchDarkly contexts for the user and organization
 */
async function configureLaunchDarkly(env: Environments, siteInfo: SiteInfoResponse): Promise<AsyncProviderConfig> {
	const ldClientId = getLdClientIdForEnv(env)

	const organizationName = siteInfo?.organizationName ?? ''
	const key = organizationName?.trim()?.toUpperCase() ?? 'DEFAULT'

	const userContext: LDContext = {
		anonymous: true,
		employeeId: 'DEFAULT',
		key: key.replace(/ /g, '_'),
		kind: 'user',
		organization: organizationName, // remove at future date once LD 3.0 is working (this is now handled in orgContext)
	}

	const orgContext: LDContext = {
		key: key.replace(/ /g, '_'),
		kind: 'organization',
		name: organizationName,
	}

	let version = 'unknown'
	try {
		const response = await fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
		const { version: metaVersion } = await response.json()
		version = metaVersion
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Failed to fetch version from meta.json:', error)
	}

	return {
		clientSideID: ldClientId,
		context: {
			kind: 'multi',
			organization: orgContext,
			user: userContext,
		},
		options: {
			application: {
				id: 'cs-client-ui',
				version: version,
			},
			eventCapacity: 500,
		},
		reactOptions: { useCamelCaseFlagKeys: false },
	}
}

/**
 * From the environment, get the correct LD client id configured in the .env files
 */
function getLdClientIdForEnv(env: Environments) {
	const ldClientIds = {
		[Environments.DEV]: import.meta.env.VITE_LD_ID_DEV,
		[Environments.INT]: import.meta.env.VITE_LD_ID_INT,
		[Environments.UAT]: import.meta.env.VITE_LD_ID_UAT,
	}

	const ldClientId = ldClientIds[env] || import.meta.env.VITE_LD_ID

	return ldClientId
}
