import { createSelector } from 'reselect'
import { RootState } from 'store/rootReducer'
import { GlobalSearchState } from './types'

const searchState = (state: RootState): GlobalSearchState => state.search
export const selectFacetsState = createSelector(searchState, ({ facets }: GlobalSearchState) => facets)
export const selectFacetSearchStatus = createSelector(searchState, (state) => state.facetSearchStatus)
export const selectOfferFetchStatus = createSelector(searchState, (state) => state.offerFetchStatus)

export const selectOfferState = createSelector(searchState, ({ offers }: GlobalSearchState) => offers)
export const selectSingleOffer = createSelector(
	searchState,
	(state: RootState, offerId: string) => offerId,
	({ offers }: GlobalSearchState, offerId: string) => {
		// Combine all the properties into one array
		const allOffers = [...offers.featured, ...offers.standard, ...offers.trending, ...offers.hero]

		return allOffers.find((offer) => offer.objectID.toLowerCase() === offerId.toLowerCase())
	},
)

export const selectMemberExclusiveFacets = createSelector(searchState, (state) =>
	state.facets.filter((facet) => facet.count > 0 && facet.isMemberExclusive),
)
export const selectNonExclusiveFacets = createSelector(searchState, (state) =>
	state.facets.filter((facet) => facet.count > 0 && !facet.isMemberExclusive),
)
