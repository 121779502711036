import { Navigate, Route, Routes } from 'react-router-dom'
import { Theme } from './theme'

export const Themes = () => {
	return (
		<Routes>
			<Route path='*' element={<Navigate to='/home' replace />} />
			<Route path={'/:themeName'} element={<Theme />} />
		</Routes>
	)
}
